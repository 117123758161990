<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="课程包图片" prop="pic_id">
        <SelectImgs :selectNum="1" :selectData="picIdData" :selectFunc="fileChoose" :idx="1"></SelectImgs>
        <span>560*336</span>
      </el-form-item>
      <el-form-item label="banner图" prop="banner_id">
        <SelectImgs :selectNum="1" :selectData="bannerIdData" :selectFunc="fileChoose" :idx="2"></SelectImgs>
        <span>1920*866</span>
      </el-form-item>
      <el-form-item label="logo" prop="logo_id">
        <SelectImgs :selectNum="1" :selectData="logoIdData" :selectFunc="fileChoose" :idx="3"></SelectImgs>
        <span>252*124</span>
      </el-form-item>
      <el-form-item label="logo描述" prop="logo_desc">
        <el-input v-model="info.logo_desc" placeholder="请输入描述"></el-input>
      </el-form-item>
      <el-form-item label="价格" prop="price">
        <el-input v-model="info.price" placeholder="请输入价格"></el-input>
      </el-form-item>
      <el-form-item label="人数" prop="people_number">
        <el-input v-model="info.people_number" placeholder="请输入人数"></el-input>
      </el-form-item>
      <el-form-item label="课时" prop="course_time">
        <el-input v-model="info.course_time" placeholder="请输入课时"></el-input>
      </el-form-item>
      <el-form-item label="授课方式" prop="teaching_method">
        <el-input v-model="info.teaching_method" placeholder="请输入授课方式"></el-input>
      </el-form-item>
      <el-form-item label="先决条件" prop="precondition">
        <el-input v-model="info.precondition" placeholder="请输入先决条件"></el-input>
      </el-form-item>
      <el-form-item label="适合专业" prop="major">
        <el-input v-model="info.major" placeholder="请输入适合专业"></el-input>
      </el-form-item>
      <el-form-item label="增长率" prop="growth_rate">
        <el-input v-model="info.growth_rate" placeholder="请输入增长率"></el-input>
      </el-form-item>
      <el-form-item label="增长内容" prop="growth_rate_content">
        <el-input type="textarea" :rows="6" v-model="info.growth_rate_content" placeholder="请输入增长内容"></el-input>
      </el-form-item>
      <el-form-item label="前景" prop="prospect">
        <el-input v-model="info.prospect" placeholder="请输入前景"></el-input>
      </el-form-item>
      <el-form-item label="前景内容" prop="prospect_content">
        <el-input type="textarea" :rows="6" v-model="info.prospect_content" placeholder="请输入前景内容"></el-input>
      </el-form-item>
      <el-form-item label="课程描述" prop="course_desc">
        <el-input v-model="info.course_desc" placeholder="请输入描述"></el-input>
      </el-form-item>
      <el-form-item label="课程介绍" prop="course_intro">
        <el-input type="textarea" :rows="6" v-model="info.course_intro" placeholder="请输入课程介绍"></el-input>
      </el-form-item>
      <el-form-item label="教学目标" prop="target">
        <el-input type="textarea" :rows="6" v-model="info.target" placeholder="请输入教学目标"></el-input>
      </el-form-item>
      <el-form-item label="课程内容" prop="course_content">
        <el-input type="textarea" :rows="6" v-model="info.course_content" placeholder="请输入课程内容"></el-input>
      </el-form-item>
      <!--el-form-item label="课程服务" prop="course_services">
        <el-input v-model="info.course_services" placeholder="请输入课程服务"></el-input>
      </el-form-item-->
      <el-form-item label="证书" prop="certificate_id">
        <SelectImgs :selectNum="1" :selectData="certificateIdData" :selectFunc="fileChoose" :idx="4"></SelectImgs>
        <span>1168*890</span>
      </el-form-item>
      <el-form-item label="证书内容" prop="certificate_content">
        <el-input type="textarea" :rows="6" v-model="info.certificate_content" placeholder="请输入证书内容"></el-input>
      </el-form-item>
      <el-form-item label="教师评价">
        <div class="inBox">
          <el-button type="warning" @click="addTeacher">新增评价</el-button>
          <div v-if="info.teacher_evaluation">
            <div class="item" v-for="(item, index) in info.teacher_evaluation" :key="index">
              <el-form-item label="姓名">
                <el-input v-model="item.title" placeholder="请输入姓名" style="width: 305px; margin-right: 10px;"></el-input>
                <el-button type="warning" @click="delTeacher(index)">删除</el-button>
              </el-form-item>
              <el-form-item label="头像">
                <SelectImgs :selectNum="1" :selectData="[item.pic]" :selectFunc="avatarChoose" :idx="index"></SelectImgs>
                <span>400*400</span>
              </el-form-item>
              <el-form-item label="描述">
                <el-input type="textarea" :rows="4" v-model="item.desc" placeholder="请输入简介"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="课程模块">
        <div class="inBox">
          <el-button type="warning" @click="addMain">新增模块</el-button>
          <div v-if="info.course_mod">
            <div class="item" v-for="(item, index) in info.course_mod" :key="index">
              <el-form-item label="标题">
                <el-input v-model="item.title" placeholder="请输入标题" style="width: 305px; margin-right: 10px;"></el-input>
                <el-button type="warning" @click="delMain(index)">删除</el-button>
              </el-form-item>
              <el-form-item label="简介">
                <el-input type="textarea" :rows="4" v-model="item.desc" placeholder="请输入简介"></el-input>
              </el-form-item>
              <div v-if="item.item">
                <el-form-item label="项目" v-for="(child, cindex) in item.item" :key="cindex">
                  <el-input v-model="item.item[cindex].title" placeholder="请输入项目" style="width: 240px; margin-right: 10px;"></el-input>
                  <el-button type="primary" @click="addItem(index)">新增</el-button>
                  <el-button type="warning" @click="delItem(index,cindex)" v-if="cindex > 0">删除</el-button>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      courseId: 0,
      dialogFormVisible: false,
      info: {
        course_id: 0,
        pic_id: 0,
        course_mod: [
          {
            title: '',
            desc: '',
            item: [{title: ''},{title: ''}]
          }
        ],
        teacher_evaluation: []
      },
      picIdData: [],
      bannerIdData: [],
      logoIdData: [],
      certificateIdData: [],
      qrCodeIdData: [],
      image_domain: this.config.IMAGE_DOMAIN,
      rules: {
        logo_desc: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        people_number: [{ required: true, message: '请输入人数', trigger: 'blur' }],
        course_time: [{ required: true, message: '请输入课时', trigger: 'blur' }],
      }
    };
  },
  created() {
    this.courseId = this.$route.query.course_id
    this.getInfo()
  },
  mounted() {
  },
  components: {
    SelectImgs
  },
  computed: {
  },
  methods: {
    getInfo() {
      this.$api.course.coursePackageInfo({course_id: this.courseId}, res => {
        if(res.errcode == 0) {
          if(res.data) {
            this.info = res.data
            if(this.info.pic_id) {
              this.picIdData = [this.info.pic_id_url[0].url]
            }
            if(this.info.banner_id) {
              this.bannerIdData = [this.info.banner_id_url[0].url]
            }
            if(this.info.logo_id) {
              this.logoIdData = [this.info.logo_id_url[0].url]
            }
            if(this.info.certificate_id) {
              this.certificateIdData = [this.info.certificate_id_url[0].url]
            }
            if(this.info.qr_code_id) {
              this.qrCodeIdData = [this.info.qr_code_id_url[0].url]
            }
            if(!this.info.course_mod) {
              this.info.course_mod = []
            }
            if(!this.info.teacher_evaluation) {
              this.info.teacher_evaluation = []
            }
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = this.info
          param.course_id = this.courseId
          this.$api.course.coursePackageEdit(param,res => {
            if(res.errcode == 0) {
              this.success(res.errmsg)
              this.$router.push({name: 'course_package'})
            } else {
              this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
    chooseFile() {
      this.dialogFormVisible = true
    },
    fileChoose(item, idx) {
      if(idx == 1) {
        this.info.pic_id = item.id
        this.picIdData = [item.pic]
      } else if(idx == 2) {
        this.info.banner_id = item.id
        this.bannerIdData = [item.pic]
      } else if(idx == 3) {
        this.info.logo_id = item.id
        this.logoIdData = [item.pic]
      } else if(idx == 4) {
        this.info.certificate_id = item.id
        this.certificateIdData = [item.pic]
      }
    },
    addMain() {
      this.info.course_mod.push({
        title: '',
        desc: '',
        item: [{title: ''},{title: ''}]
      })
    },
    delMain(idx) {
      this.info.course_mod.splice(idx,1)
    },
    addItem(idx) {
      this.info.course_mod[idx].item.push({title: ''})
    },
    delItem(idx,cidx) {
      this.info.course_mod[idx].item.splice(cidx,1)
    },
    addTeacher() {
      this.info.teacher_evaluation.push({
        title: '',
        desc: '',
        pic: ''
      })
    },
    avatarChoose(item,idx) {
      this.info.teacher_evaluation[idx].pic = item.pic
      console.log(item)
      console.log(idx)
    },
    delTeacher(idx) {
      this.info.teacher_evaluation.splice(idx,1)
    }
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
  .inBox .item {
    background-color: #f5f5f5;
    margin-top: 5px;
    padding: 10px;
  }
</style>
